import React, { useEffect, useState } from "react";
import ReactWordcloud from "react-wordcloud";
import "./WordCloud.css";
import { select } from "d3-selection";
import { useWindowWidth } from "../useWindowSize/useWindowSize";

const REFRESH_DELAY = 20 * 1000;

const median = (a) => {
  const arrSort = a.sort();
  const mid = Math.ceil(arrSort.length / 2);
  const median =
    arrSort.length % 2 === 0
      ? (arrSort[mid] + arrSort[mid - 1]) / 2
      : arrSort[mid - 1];

  return median;
};

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const WordCloud = () => {
  const [words, setWords] = useState(false);
  const [switch_value, setSwitchValue] = useState(75);
  const [latestRefresh, setRefreshTime] = useState(new Date());
  const [windowSize, setWindowSize] = useState([
    Math.max(window.innerWidth, useWindowWidth()[0]),
    window.innerHeight,
  ]);

  const calculateWordCount = () => {
    const MOBILE_WORD_COUNT = 70;
    const wordAreaRatio = MOBILE_WORD_COUNT / (412 * 800);
    const newWordCount = Math.round(
      wordAreaRatio * windowSize[0] * windowSize[1]
    );
    // console.log(`[${windowSize[0]}, ${windowSize[1]}] = ${newWordCount} Words`);
    return newWordCount;
  };

  const fetchWords = () => {
    fetch(`${process.env.PUBLIC_URL}/FoundHashtags.json`, {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setWords(data);
      });
  };

  useEffect(() => {
    if (!words) {
      fetchWords();
    }

    // if (new Date() - latestRefresh >= REFRESH_DELAY) {
    //   sleep(REFRESH_DELAY).then(() => {
    //     console.log("updating start time");
    //     setRefreshTime(new Date());
    //     console.log("refreshing words");
    //     fetchWords();
    //   });
    // }

    const interval = setInterval(
      () => setRefreshTime(Date.now()),
      REFRESH_DELAY
    );
    return () => {
      clearInterval(interval);
    };
  }, [words]);

  function getCallback(callback) {
    return function (word, event) {
      const isActive = callback !== "onWordMouseOut";
      const element = event.target;
      const text = select(element);
      text
        .on("click", () => {
          if (isActive) {
            window.open(
              `https://twitter.com/hashtag/${word.text.replace("#", "")}`,
              "_blank"
            );
          }
        })
        .transition()
        .attr("background", "white");
      // .attr("font-size", isActive ? "300%" : "100%");
      // .attr("text-decoration", isActive ? "underline" : "none");
    };
  }

  const getWordColor = (word, switch_value = 75) => {
    if (word.text === "blvote") {
      return "red";
    }
    if (word.value > switch_value) {
      return "black";
    }
    return "gray";
  };

  const callbacks = (switch_value = 75) => {
    return {
      getWordColor: (word) => getWordColor(word, switch_value),
      getWordTooltip: (word) => word.text,
      onWordClick: getCallback("onWordClick"),
      onWordMouseOut: getCallback("onWordMouseOut"),
      onWordMouseOver: getCallback("onWordMouseOver"),
    };
  };

  // if (words) {
  //   console.time("sleep");
  //   console.log("start slee");
  //   sleep(15 * 1000).then(() => {
  //     console.timeEnd("sleep");
  //     console.log("end sleep");
  //     fetchWords();
  //   });
  // }

  // console.log("latestRefresh", latestRefresh);

  const newWindowWidth = useWindowWidth();
  if (newWindowWidth !== windowSize[0]) {
    if (newWindowWidth !== 0) {
      setWindowSize([newWindowWidth, window.innerHeight]);
    }
  }

  const filtered_words = words
    ? words.slice(0, calculateWordCount())
    : [{ hashtag: "#blvote", value: 0 }];

  const new_switch_value = median(filtered_words.map((word) => word.value));
  if (switch_value !== new_switch_value) {
    // console.log({ switch_value, new_switch_value });
    setSwitchValue(new_switch_value);
  }

  // console.log(`
  // filtered_words: ${filtered_words.length}
  // values: ${filtered_words.map((word) => word.value)}
  // median:${median(filtered_words.map((word) => word.value))}
  // min:${filtered_words
  //   .map((word) => word.value)
  //   .reduce((a, v) => (a > v ? v : a))}
  // max:${filtered_words
  //   .map((word) => word.value)
  //   .reduce((a, v) => (a > v ? a : v))}
  // `);

  return (
    <div className="hashtag--wrapper">
      <ReactWordcloud
        words={filtered_words}
        callbacks={callbacks(switch_value)}
        options={{
          fontFamily: "Cornerstone",
          padding: 1,
          rotations: 2,
          rotationAngles: [0, 90],
          transitionDuration: 3000,
          fontSizes: [16, 32],
        }}
      />
    </div>
  );
};

export default WordCloud;
