import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

import "./navbar.css";

class NavbarView extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">
          <img
            src="/BLVLogo.png"
            width="90"
            height="90"
            className="d-inline-block align-top"
            alt="Black Lives Vote Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#timer">846 Timer</Nav.Link>
            {/* <Nav.Link href="#map">Map</Nav.Link> */}
            <Nav.Link href="#vote">Voter Registration</Nav.Link>
            <Nav.Link href="#stream">Stream</Nav.Link>
            <Nav.Link href="#hashtags">Hashtags</Nav.Link>
            <Nav.Link
              href="https://twitter.com/BLM_BLVote"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavbarView;
