import React, { useRef, useEffect, useState } from "react";

import "./FullWidthText.css";

const FullWidthText = (props) => {
  const THRESHOLD = 1.99;
  const FONT_STEP = 2;
  const SMALL_STEP = 0.1;
  const ref = useRef(null);
  const {
    text,
    className,
    starting_font,
    style,
    ready,
    ready_callback,
    ...rest
  } = props;

  let clsName = "";
  if (typeof className !== "undefined") {
    clsName = className;
  }

  let defaultStyle = {
    fontFamily: "Cornerstone",
    width: "fit-content",
    margin: "auto",
  };
  if (typeof style !== "undefined") {
    defaultStyle = Object.assign(defaultStyle, style);
  }

  const [state, setState] = useState({
    font_size: starting_font || 5,
    previous_height: starting_font * 2 || 20,
    done: false,
    callback_triggered: false,
  });

  useEffect(() => {
    const { font_size, previous_height, done, callback_triggered } = state;
    const height = parseFloat(
      window.getComputedStyle(ref.current).height.replace("px", "")
    );

    const updState = Object.assign({}, state);
    // console.log({ font_size, previous_height, done });

    // backs the size up to fit back on one line
    if (done) {
      if (height > previous_height / THRESHOLD) {
        updState.font_size -= SMALL_STEP;
        setState(updState);
        return;
      }
      if (!callback_triggered) {
        // fires off the callback once it's on one line again
        if (typeof ready_callback !== "undefined") {
          ready_callback();
        }
        updState.callback_triggered = true;
        setState(updState);
      }
      return;
    }

    // checks to see if the text has wrapped to two lines
    if ((height > THRESHOLD * previous_height) & (height >= 0)) {
      updState.previous_height = height;
      updState.done = true;
    } else {
      updState.font_size = font_size + FONT_STEP;
      updState.previous_height = height;
    }

    if (!done) {
      setState(updState);
    }
  }, [state, ready_callback]);

  const { font_size, done } = state;

  defaultStyle = Object.assign(defaultStyle, { fontSize: `${font_size}px` });

  if (typeof ready !== "undefined") {
    defaultStyle = Object.assign(defaultStyle, {
      visibility: done & ready ? "visible" : "hidden",
    });
  }

  return (
    <div
      {...rest}
      ref={ref}
      style={defaultStyle}
      className={`FullWidthText ${clsName}`}
    >
      {text}
    </div>
  );
};

export default FullWidthText;
