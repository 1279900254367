import React from "react";
import "./Quote.css";

function randomLstItem(n) {
  const index = Math.min(Math.floor(Math.random() * n), n - 1);
  return index;
}

const Quote = (params) => {
  const { text, author } = params;
  return (
    <blockquote className="otro-blockquote">
      {text}
      <span>{author}</span>
    </blockquote>
  );
};

export const RandomQuote = (params) => {
  const { QuoteList } = params;
  const quoteIndex = randomLstItem(QuoteList.length);
  return <Quote {...QuoteList[quoteIndex]} />;
};

export default Quote;
