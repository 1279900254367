import React from "react";
import "./Dedication.css";

const Dedication = () => {
  return (
    <div className="dedication__wrapper">
      <img className="dedication__photo" src="/Tamara.png" alt="Tamara" />
      <div className="dedication__text">
        This site is dedicated to my goddaughter, Tamara, and all the other
        girls and boys growing up with injustice; that our efforts help provide
        them with better, longer, happier lives filled with opportunity,
        equality, and prosperity.
      </div>
    </div>
  );
};

export default Dedication;
