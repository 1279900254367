import React, { useState, Fragment } from "react";
import "./VoterRegistration.css";
import { iframeResizer } from "iframe-resizer";
import Spinner from "react-bootstrap/Spinner";

const VoterRegistration = (params) => {
  const [isLoaded, setLoaded] = useState(false);

  return (
    <Fragment>
      {!isLoaded ? (
        <div className="vote--wrapper">
          <div className="vote__spinner--wrapper">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <h2 className="vote__spinner--text">Voter Registration</h2>
          </div>
        </div>
      ) : null}
      <iframe
        title="Rock The Vote"
        id="rockthevote"
        src="https://register.rockthevote.com"
        width="100%"
        scrolling="no"
        height={isLoaded ? 500 : 0}
        frameBorder="0"
        onLoad={() => {
          iframeResizer({ log: false, checkOrigin: false }, "#rockthevote");
          setLoaded(true);
        }}
      ></iframe>
    </Fragment>
  );
};

export default VoterRegistration;
