import React from "react";

import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container text-center">
        {/* <a href="#"><i class="fa fa-facebook"></i></a> */}
        <a
          href="https://twitter.com/BLM_BLVote"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-twitter"></i>
        </a>
        {/* <a href="#"><i class="fa fa-linkedin"></i></a>
    <a href="#"><i class="fa fa-google-plus"></i></a>
    <a href="#"><i class="fa fa-skype"></i></a> */}
      </div>
    </footer>
  );
};

export default Footer;
