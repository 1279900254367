import React, { useState, useEffect } from "react";
import "./TweetCollection.css";
import useWindowSize from "../useWindowSize";

const TweetCollection = (params) => {
  const { url, header } = params;

  const [windowSize, setWindowSize] = useState(useWindowSize());
  const [scriptAdded, setScriptAdded] = useState(false);

  const newWindowSize = useWindowSize();
  if (newWindowSize !== windowSize) {
    setWindowSize(newWindowSize);
  }

  // if (window.performance) {
  //   console.log("performance.navigation.type", performance.navigation.type);
  // }

  useEffect(() => {
    if (!scriptAdded) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.charset = "utf-8";
      document.body.appendChild(script);

      // console.log("Script Added");
      setScriptAdded(true);
    }
  }, [scriptAdded]);

  // Sets the tweet stream to the full height if it is > 400px,
  // otherwise it's the larger of 400px or half the screen
  const vh50 = Math.max(
    windowSize[0] * 0.8,
    Math.max(windowSize[1] * 0.5, Math.min(windowSize[1], 400))
  );

  return (
    <a className="twitter-timeline" data-height={vh50} href={url}>
      {header}
    </a>
  );
};

export default TweetCollection;
