import React, { useState, useEffect } from "react";

import "./FloydTimer.css";

const TOTAL_TIME = 526;
const UNCONSCIOUS_TIME = 162;

const zeroPad = (str) => {
  str = String(str);
  if (str.length === 1) {
    return `0${str}`;
  }
  return str;
};

const SecondConverter = (sec) => {
  const minutes = Math.floor(sec / 60);
  const seconds = sec - minutes * 60;
  return [minutes, seconds];
};

const inRange = (sec, lower, upper) => {
  if ((sec >= lower) & (sec <= upper)) {
    return true;
  }
  return false;
};

const timerFontColor = (seconds) => {
  const inverse_seconds = TOTAL_TIME - seconds;
  if (inverse_seconds <= 0.4 * UNCONSCIOUS_TIME) {
    return "white";
  }

  // if (inverse_seconds <= 0.9 * UNCONSCIOUS_TIME) {
  //   return "white";
  // }

  return "red";
};

const timerBackgroundBrightness = (seconds) => {
  const inverse_seconds = TOTAL_TIME - seconds;

  if (inverse_seconds < UNCONSCIOUS_TIME) {
    return 1 - inverse_seconds / (1.1 * UNCONSCIOUS_TIME);
  }
  return 0;
};

const LastWords = (seconds) => {
  const lastWordsDetail = [
    [19, 21, "My Stomach Hurts"],
    [22, 24, "My Neck Hurts"],
    [25, 26, "Everything Hurts"],
    [43, 45, "Please. Please. Please."],
    [46, 48, "I can't breathe!"],
    [69, 62, "Please, man!"],
    [75, 78, "Please!"],
    [80, 82, "Somebody help me!"],
    [85, 90, "I can't breathe man, please!"],
    [95, 98, "Please! The knee in my neck!"],
    [102, 105, "I can't breathe!"],
    [128, 130, "Police: Get up and get in the car!"],
    [131, 133, "I will! I will!"],
    [136, 139, "Mama!"],
    [141, 144, "Police: Get in the car!"],
    [145, 148, "I can't! (they're holding him down)"],
    [149, 150, "Mama!"],
    [153, 155, "I cannot breathe"],
    [158, 161, "I cannot breathe"],
    [UNCONSCIOUS_TIME, TOTAL_TIME, "(Unconscious)"],
  ];
  const inverse_seconds = TOTAL_TIME - seconds;
  for (var i = 0; i < lastWordsDetail.length; i++) {
    // console.log(i, lastWordsDetail[i]);
    const [lower, upper, text] = lastWordsDetail[i];
    // console.log({
    //   inverse_seconds,
    //   lower,
    //   upper,
    //   inRange: inRange(inverse_seconds, lower, upper),
    //   text,
    // });
    if (inRange(inverse_seconds, lower, upper)) {
      return text;
    }
  }
  return "";
};

const FloydTimer = () => {
  const COOLDOWN = 5;
  const [seconds, setSeconds] = useState(TOTAL_TIME);

  function reset() {
    setSeconds(TOTAL_TIME);
  }

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      if (seconds <= -COOLDOWN) {
        reset();
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  const [formatted_mintes, formatted_seconds] = SecondConverter(seconds);
  // document.title = `BLVote ${formatted_mintes}:${zeroPad(formatted_seconds)}`;

  const fontStyle = { color: timerFontColor(seconds) };

  return (
    <div className="FloydTimer--wrapper">
      <div
        className="FloydTimer--image"
        style={{
          filter: `brightness(${timerBackgroundBrightness(seconds)})`,
          backgroundImage: `url("george-floyd-mural.jpg")`,
        }}
      ></div>
      <div className="FloydTimer__text--wrapper">
        <div className="FloydTimer--clock" style={fontStyle}>
          {`${formatted_mintes}:${zeroPad(formatted_seconds)}`}
        </div>
        <div style={fontStyle} className="FloydTimer--last_words">
          {LastWords(seconds)}
        </div>
      </div>
    </div>
  );
};

export default FloydTimer;
