import React from "react";
import "./App.css";
import Navbar from "../src/components/navbar";
import Poster from "./components/poster";
import DefaultMargin from "./components/default_margin";
import FloydTimer from "./components/FloydTimer";
import Footer from "./components/footer";
import TweetCollection from "./components/TweetCollection";
import VoterRegistration from "./components/VoterRegistration/VoterRegistration";
import Dedication from "./components/Dedication";
import WordCloud from "./components/WordCloud";
import { RandomQuote } from "./components/Quote/Quote";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const QUOTES = [
  {
    author: "Michelle Obama",
    text:
      "You’ve got to vote, vote, vote, vote. That’s it; that's the way we move forward.",
  },
  {
    author: "John Lewis",
    text:
      "The vote is precious. It is the most powerful non-violent tool we have in a democratic society, and we must use it.",
  },
  {
    author: "Larry J. Sabato",
    text: "Every election is determined by the people who show up.",
  },
  {
    author: "Peggy Noonan",
    text:
      "Our political leaders will know our priorities only if we tell them, again and again, and if those priorities begin to show up in the polls.",
  },
  {
    author: "George Jean Nathan",
    text: "Bad officials are elected by good citizens who do not vote.",
  },
];

function App() {
  return (
    <div className="App">
      <Navbar />

      <div
        className="jumbotron jumbotron--image"
        style={{ backgroundImage: `url("/BLM_Protest.jpg")` }}
      ></div>
      <DefaultMargin>
        <Poster />

        <a name="timer" href="#timer" visibility="hidden"></a>
        <FloydTimer />
        {/* <a name="map" href="#map" visibility="hidden" /> */}
        {/* <Placeholder text="Twitter Map Placeholder" modifier={"map"} /> */}

        <a name="vote" href="#vote" visibility="hidden"></a>

        <RandomQuote QuoteList={QUOTES} />

        <VoterRegistration />

        <h2 className="tweet_stream__header">BLVote Stream</h2>
        <i>
          The BLVote twitter tollection is created automatically, pulling in
          tweets based on their hashtags
        </i>
        <br />
        <i>The word cloud makes a really great background!</i>
      </DefaultMargin>

      <a name="hashtags" href="#hashtags" visibility="hidden"></a>
      <WordCloud />

      <DefaultMargin>
        <a name="stream" href="#map" visibility="hidden"></a>
        <TweetCollection
          url="https://twitter.com/BLM_BLVote/timelines/1269358770691477506?ref_src=twsrc%5Etfw"
          header="BLVote Stream"
        />
        <Dedication />
      </DefaultMargin>
      <Footer />
    </div>
  );
}

export default App;
