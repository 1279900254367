import React, { useState } from "react";

import "./poster.css";
import FullWidthText from "../FullWidthText";
import useWindowSize from "../useWindowSize";

const PosterView = () => {
  const posterText = [
    "It's past time to end the killing of unarmed black men.",
    "Black Lives Matter",
    "But since politicians don't care, let's remind them",
    "Black Lives Vote",
    "It's past time to hold police accountable for their actions.",
    "No Justice, No Peace!",
    "It's past time to vote justice into office.",
  ];

  const [windowSize, setWindowSize] = useState(useWindowSize());
  const [allReady, setAllReady] = useState(false);
  const [readyItems, setItem] = useState(
    posterText.reduce(
      (dict, text, index) => Object.assign(dict, { [index]: false }),
      {}
    )
  );

  const newWindowSize = useWindowSize();
  if (newWindowSize !== windowSize) {
    setWindowSize(newWindowSize);
    setItem(
      posterText.reduce(
        (dict, text, index) => Object.assign(dict, { [index]: false }),
        {}
      )
    );
    setAllReady(false);
  }

  const setItemHelper = (index) => {
    const updReadyItems = Object.assign(readyItems, { [index]: true });
    setAllReady(Object.values(updReadyItems).every((bool) => bool));
    setItem(updReadyItems);
  };

  return (
    <div
      className="poster--wrapper"
      style={{
        visibility: allReady ? "visible" : "hidden",
      }}
    >
      {posterText.map((txt, index) => {
        return (
          <FullWidthText
            key={`${index}-${windowSize[0]}-${windowSize}`}
            text={txt}
            ready_callback={() => setItemHelper(index)}
            starting_font={windowSize / 50}
          />
        );
      })}
    </div>
  );
};

export default PosterView;
